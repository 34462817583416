import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Scale, CoreScaleOptions } from 'chart.js';
import { cc_offwhite } from '../../theme/colors';

export interface BarTopping {
  label: string;
  color: string;
  data: number[];
}

const transformBarToppingIntoChartData = (
  emptyLabel: string,
  toppings: BarTopping[],
  xLabels: string[],
) => {
  return {
    labels: xLabels,
    datasets:
      toppings.length > 0
        ? toppings.map((val) => {
            return {
              label: val.label,
              data: val.data.map((v) => Math.round(v)),
              backgroundColor: val.color,
            };
          })
        : [{ label: emptyLabel, data: [], backgroundColor: 'rgba(0, 0, 0, 0)' }],
  };
};

const ChocolateBar: React.FC<{
  emptyLabel?: string;
  xLabels: string[];
  toppings: BarTopping[];
  horizontal?: boolean;
}> = ({ emptyLabel = 'No data', toppings, xLabels, horizontal = false }) => {
  const toppingsAxisDef = {
    stacked: true,
    ticks: {
      callback: function formatToppingValue(
        this: Scale<CoreScaleOptions>,
        value: string | number,
      ): string {
        const numValue = Number(value);
        if (Math.abs(numValue) >= 10000) {
          return `${(numValue / 1000).toFixed(0)}k`;
        }
        if (Math.abs(numValue) >= 1000) {
          return `${(numValue / 1000).toFixed(1)}k`;
        }
        return toppings.length > 0 ? numValue.toLocaleString('en-US') : '';
      },
      color: cc_offwhite,
    },
    grid: {
      color: 'rgba(0, 0, 0, 0)',
    },
    border: {
      color: cc_offwhite,
    },
  };

  const labelsAxisDef = {
    stacked: true,
    ticks: {
      display: true,
      color: cc_offwhite,
      callback: function formatLabelValue(
        this: Scale<CoreScaleOptions>,
        tickValue: string | number,
        index: number,
      ): string {
        return xLabels[index].length > 2 ? `'${xLabels[index].substring(2, 4)}` : xLabels[index];
      },
      autoSkip: false,
    },
    grid: {
      color: 'rgba(0, 0, 0, 0)',
    },
    border: {
      color: cc_offwhite,
    },
  };

  return (
    <div style={{ width: '23em', height: '12em' }}>
      <Bar
        data={transformBarToppingIntoChartData(emptyLabel, toppings, xLabels)}
        options={{
          indexAxis: horizontal ? 'y' : 'x',
          plugins: {
            legend: {
              display: true,
              position: 'left',
              labels: {
                boxWidth: 8,
                boxHeight: 10,
                pointStyle: 'circle',
                usePointStyle: true,
                color: cc_offwhite,
              },
              maxWidth: 9999,
            },
          },
          responsive: true,
          maintainAspectRatio: false,

          elements: {
            bar: {
              borderWidth: 2,
              borderSkipped: 'start',
              borderColor: cc_offwhite,
            },
          },

          scales: {
            x: horizontal ? toppingsAxisDef : labelsAxisDef,
            y: horizontal ? labelsAxisDef : toppingsAxisDef,
          },
        }}
      />
    </div>
  );
};

export default ChocolateBar;
