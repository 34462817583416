import { SpeciesType, getETSSpecies } from '@nai/ets-polygons';
import { CreditSchemeType, ReportCategory } from '@nai/ets-polygons/dist/models/segments';

export const isReportCategoryFuture = (report_category: ReportCategory | undefined): boolean =>
  report_category === 'future_native_planting' || report_category === 'future_exotic_planting';

export const getNewReportCategory = (
  report_category: ReportCategory | undefined,
  newYear: number | undefined,
  new_species: SpeciesType | undefined,
  newCreditScheme?: CreditSchemeType,
): ReportCategory | undefined => {
  if (newCreditScheme === 'alternative_scheme') {
    return 'native_carboncrop';
  }
  if (new_species === 'blank') {
    return undefined;
  }
  const getCorrectCategory = (isNative: boolean, isFuture: boolean) => {
    if (isNative) {
      return isFuture ? 'future_native_planting' : 'existing_indigenous_ets_forest';
    }
    return isFuture ? 'future_exotic_planting' : 'existing_exotic_ets_forest';
  };

  const isReportCategoryNative =
    report_category === 'existing_indigenous_ets_forest' ||
    report_category === 'future_native_planting';

  const currentYear = new Date().getFullYear();
  const isDateFuture = newYear !== undefined && newYear > currentYear;

  const isDateIrrelevant =
    newYear === undefined || (newYear !== undefined && (newYear <= 999 || currentYear === newYear));

  const defaultReportCategory =
    report_category === 'native_carboncrop' ? undefined : report_category;

  if (
    ![
      'existing_exotic_ets_forest',
      'existing_indigenous_ets_forest',
      'future_exotic_planting',
      'future_native_planting',
      'native_carboncrop',
      undefined,
    ].includes(report_category)
  ) {
    return defaultReportCategory;
  }

  if (new_species === undefined) {
    if (isDateIrrelevant) {
      return defaultReportCategory;
    }
    return report_category && getCorrectCategory(isReportCategoryNative, isDateFuture);
  }
  const isSpeciesNative = getETSSpecies(new_species) === 'indigenous';
  if (isDateIrrelevant) {
    return getCorrectCategory(isSpeciesNative, isReportCategoryFuture(report_category));
  }
  return getCorrectCategory(isSpeciesNative, isDateFuture);
};
