import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unparse } from 'papaparse';
import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { UserInfoResponse } from '../apis/generated';
import { IconButton } from '../components/buttons/IconButton';
import { ModifierFunction, StoreState } from '../contexts/remote-data/sources/Store';
import { useCurrentCompanyIdUltimate } from '../contexts/remote-data/useCurrentCompanyIdUltimate';
import { useIsSite } from '../contexts/remote-data/useIsSite';
import { AnyTask } from '../contexts/remote-data/useSiteTasks';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import { ProfileBubble } from '../customer/comments/ProfileBubble';
import {
  generic_status_definition,
  task_types_definitions,
} from '../customer/overview/TasksDefinition';
import Fantail from '../theme/images/branding/birds/fantail.png';
import { FilterDropdown, FilterLabel, FilterOption } from './FilterDropdown';
import TaskEntry from './TaskEntry';
import styles from './TasksDashboard.module.scss';
import { CompanyUser, useCompanyUsers } from './useCompanyUsers';
import NewTaskModal from './NewTaskModal';
import { useIsTaskEditor } from '../contexts/remote-data/useIsTaskEditor';

const DISPLAY_SIZE = 10;

export const makeAssigneeList = (
  users: never[] | CompanyUser[] | undefined,
  userMe: UserInfoResponse | undefined,
): (FilterOption<number | undefined> | FilterLabel)[] => {
  return [
    { display_name: 'Unassigned', key: undefined },
    {
      display_name: 'Assigned to me',
      key: userMe?.user_id,
      badge: <ProfileBubble email={userMe?.email} />,
    },
    { label_name: '' },
  ].concat(
    (users || [])
      .filter((item) => item.user_id !== userMe?.user_id)
      .map((item) => {
        return {
          display_name: item.email,
          badge: <ProfileBubble email={item.email} key={item.user_id} />,
          key: item.user_id,
          email: item.email,
        };
      })
      .sort((a, b) => {
        return a.display_name.localeCompare(b.display_name);
      }),
  );
};

export const assigneeFilter = (
  assignee: number | undefined,
  filterAssignee: Array<number | undefined>,
) =>
  filterAssignee.length === 0 ||
  filterAssignee.includes(assignee) ||
  (filterAssignee.includes(undefined) && assignee === null);

const TasksDashboard: React.FC<{
  tasks?: AnyTask[];
  setTasks: ModifierFunction<AnyTask[]>;
  state: StoreState;
  error?: Error;
}> = ({ tasks, setTasks, state, error }) => {
  const { userMe } = useUserMe();
  const companyId = useCurrentCompanyIdUltimate();
  const { users } = useCompanyUsers(companyId);
  const isSite = useIsSite();
  const isTaskEditor = useIsTaskEditor();

  const [searchString, setSearchString] = useState<string>('');
  const [numDisplay, setNumDisplay] = useState<number>(DISPLAY_SIZE);
  const [filterType, setFilterType] = useState<AnyTask['task_type'][]>([]);
  const [filterStatus, setFilterStatus] = useState<AnyTask['status'][]>([]);
  const [filterAssignee, setFilterAssignee] = useState<Array<number | undefined>>([]);

  const types: FilterOption<AnyTask['task_type']>[] = useMemo(() => {
    const mapkeys = [...new Set(tasks?.map((task) => task.task_type))];
    return mapkeys
      .filter((key) => tasks?.some((task) => task.task_type === key))
      .map((key) => {
        return {
          display_name: task_types_definitions.find((item) => item.type === key)?.typeName || key,
          key,
        };
      });
  }, [tasks]);

  const genericStatuses: (FilterOption<AnyTask['status']> | FilterLabel)[] =
    generic_status_definition.map((status) => {
      return { key: status.status, display_name: status.statusName };
    });

  const statuses: (FilterOption<AnyTask['status']> | FilterLabel)[] = useMemo(
    () =>
      genericStatuses.concat(
        task_types_definitions
          .filter(
            (item) =>
              types.some((t) => t.key === item.type) &&
              (filterType.includes(item.type) || filterType.length === 0) &&
              item.status !== undefined,
          )
          .reduce((acc, item) => {
            const statusExists = acc.some(
              (existing) =>
                existing.status?.statusNames.length === item.status?.statusNames.length &&
                existing.status?.statusNames.every((s) =>
                  item.status?.statusNames.some((is) => is.status === s.status),
                ),
            );

            return statusExists ? acc : [...acc, item];
          }, [] as typeof task_types_definitions)
          .map((item) => {
            return [
              { label_name: item.status?.name ?? '' },
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...item.status!.statusNames.map((status) => {
                return { key: status.status, display_name: status.statusName };
              }),
            ];
          })
          .flat(),
      ),
    [filterType, genericStatuses, types],
  );

  const [showCancelledTasks, setShowCancelledTasks] = useState(false);

  const assignees: (FilterOption<number | undefined> | FilterLabel)[] = useMemo(
    () => makeAssigneeList(users, userMe),
    [users, userMe],
  );

  const filterTasksByType = (tasks: AnyTask[], filterType: AnyTask['task_type'][]) => {
    return tasks.filter((task) => filterType.length === 0 || filterType.includes(task.task_type));
  };

  const filterTasksByStatus = (tasks: AnyTask[], filterStatus: AnyTask['status'][]) =>
    tasks.filter((task) => filterStatus.length === 0 || filterStatus.includes(task.status));

  const filterTasksByCancelled = (tasks: AnyTask[], showCancelledTasks: boolean) =>
    tasks.filter((task) => showCancelledTasks || task.status !== 'cancelled');

  const filterTasksByAssignee = (tasks: AnyTask[], filterAssignee: Array<number | undefined>) =>
    tasks.filter((task) => assigneeFilter(task.assignee, filterAssignee));

  const filterTasksBySearch = (tasks: AnyTask[], searchString: string) => {
    return tasks.filter((task) => {
      const searchKey = searchString.toLowerCase().trim();
      return (
        `${task.customer_id}`.toLowerCase().includes(searchKey) ||
        (task.site_name && task.site_name.toLowerCase().includes(searchKey))
      );
    });
  };

  const filteredTasks = useMemo(() => {
    return filterTasksBySearch(
      filterTasksByAssignee(
        filterTasksByCancelled(
          filterTasksByStatus(filterTasksByType(tasks || [], filterType), filterStatus),
          showCancelledTasks,
        ),
        filterAssignee,
      ),
      searchString,
    );
  }, [tasks, searchString, filterType, filterStatus, filterAssignee, showCancelledTasks]);

  const sortedTasks = useMemo(() => {
    const sort = filteredTasks?.sort((a, b) =>
      (a.update_time || '') > (b.update_time || '') ? -1 : 1,
    );
    return sort.reduce((acc, task) => {
      if (acc.some((item) => item.customer_id === task.customer_id)) {
        return acc;
      }
      return acc.concat(sort.filter((item) => item.customer_id === task.customer_id));
    }, [] as AnyTask[]);
  }, [filteredTasks]);

  const cappedTasks = useMemo(() => {
    return sortedTasks.slice(0, numDisplay);
  }, [sortedTasks, numDisplay]);

  const fetchSites = useCallback(async () => {
    setNumDisplay((n) => n + DISPLAY_SIZE);
  }, [setNumDisplay]);

  const downloadViewAsCsv = useCallback(() => {
    const rowString = unparse(
      sortedTasks?.map((task) => {
        return {
          customer_id: task.customer_id,
          site_name: task.site_name,
          task_type: task.task_type,
          status: task.status,
          update_time: task.update_time,
          assignee: users?.find((userInfo) => userInfo.user_id === task.assignee)?.email,
        };
      }) ?? [],
    );
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${rowString}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'tasks.csv');
    document.body.appendChild(link);
    link.click();
  }, [sortedTasks, users]);

  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  return (
    <>
      <NewTaskModal
        showCreateTaskModal={showCreateTaskModal}
        setShowCreateTaskModal={setShowCreateTaskModal}
      />
      <Container fluid className="m-0 p-4 bg-birch">
        <div className=" d-flex mb-4 justify-content-between align-items-baseline">
          <div className="d-flex flex-row gap-2 align-items-baseline">
            <h3>Tasks</h3>
            {state === 'downloading' && !tasks && (
              <Spinner
                animation="border"
                className="me-2"
                style={{ height: '1.3em', width: '1.3em' }}
              />
            )}
            {state === 'error' && (
              <Alert variant="danger">Failed to load properties: {error?.toString()}</Alert>
            )}
          </div>
          <div className="d-flex flex-row gap-2">
            {isSite && isTaskEditor && (
              <Button variant="primary" onClick={() => setShowCreateTaskModal(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                New Task
              </Button>
            )}
            <IconButton className="d-flex gap-2" icon={faDownload} onClick={downloadViewAsCsv}>
              Export View
            </IconButton>
          </div>
        </div>
        {state === 'error' && (
          <Alert variant="danger">Failed to load company tasks: {error?.toString()}</Alert>
        )}

        <div className="d-flex flex-row flex-wrap justify-content-between mb-4">
          <div className="d-flex flex-row gap-2 flex-wrap flex-grow-1">
            {!isSite && (
              <div className="w-50">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className={`${styles.search_input}`}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                />
              </div>
            )}
            <FilterDropdown
              filterName="Type"
              filterOptions={types}
              filter={filterType}
              setFilter={setFilterType}
            />
            <FilterDropdown
              filterName="Status"
              filterOptions={statuses}
              filter={filterStatus}
              setFilter={setFilterStatus}
              searcheable
            />
            {isTaskEditor && (
              <FilterDropdown
                filterName="Assignee"
                filterOptions={assignees}
                filter={filterAssignee}
                setFilter={setFilterAssignee}
                searcheable
              />
            )}
            <div className="d-flex gap-1  justify-content-center  align-items-center">
              <div className="px-2">Show Cancelled Tasks</div>
              <Form.Check
                type="switch"
                id="chkShowCancelledTasks"
                checked={showCancelledTasks}
                onChange={(e) => setShowCancelledTasks(e.target.checked)}
                className={
                  showCancelledTasks ? 'form-check-always-green' : 'form-check-always-grey'
                }
              />
            </div>
          </div>
        </div>
        {tasks && tasks.length > 0 && (
          <>
            <Form.Label>
              Showing {sortedTasks.length} of {tasks ? tasks.length : '?'} tasks
            </Form.Label>
            <div className={`py-2 ${styles.tasks_table}`}>
              <Row className="text-muted m-0 py-3">
                <Col md={1}>&nbsp;</Col>
                {!isSite && (
                  <Col md={4}>
                    <strong>Site</strong>
                  </Col>
                )}
                <Col md={isSite ? 4 : 3}>
                  <strong>Type</strong>
                </Col>
                <Col md={isSite ? 5 : 2}>
                  <strong>Status</strong>
                </Col>
                {isTaskEditor && (
                  <Col md={1}>
                    <strong>Assignee</strong>
                  </Col>
                )}
              </Row>
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchSites}
                hasMore={cappedTasks.length < sortedTasks.length}
              >
                {cappedTasks &&
                  cappedTasks.map((task) => {
                    return (
                      <TaskEntry
                        tasks={tasks}
                        task={task}
                        setTask={(updater) => {
                          setTasks((tasks) => {
                            return tasks?.map((t) => (t.uuid === task.uuid ? updater(t) : t));
                          });
                        }}
                        key={`${task.customer_id} ${task.uuid}`}
                        showAssignee={isTaskEditor}
                      />
                    );
                  })}
              </InfiniteScroll>
            </div>
          </>
        )}
        {tasks && tasks.length === 0 && (
          <div className="d-flex align-items-center flex-column gap-5 p-5">
            <Image src={Fantail} style={{ width: '160px' }} />

            <div className="text-muted">No tasks to see here!</div>
          </div>
        )}
      </Container>
    </>
  );
};

export default TasksDashboard;
