import { Dimensions } from '../../apis/generated';

export type DimensionFilter = (d: Dimensions) => boolean;

export type DimensionDict = {
  [key: string]: Dimensions;
};

export function getDimensionKeys(dimensions: DimensionDict, filter: DimensionFilter): string[] {
  return Object.keys(dimensions).filter((key) => filter(dimensions[key]));
}
