export const download_csv = (data: BlobPart[], filename: string): void => {
  const blob = new Blob(data, {
    type: 'text/csv',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};
