import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApiService } from '../apis/api-service';
import { AuthFeature, InvoiceTaskProperties } from '../apis/generated';
import { Circled } from '../components/Circled';
import { UpdaterFunction } from '../contexts/remote-data/sources/Store';
import { AnyTask } from '../contexts/remote-data/useSiteTasks';
import {
  generic_status_definition,
  taskDefinitionsByType,
} from '../customer/overview/TasksDefinition';
import { EditAssigneeDropdown } from './EditAssigneeDropdown';
import styles from './TaskEntry.module.scss';
import { useIsSite } from '../contexts/remote-data/useIsSite';
import { useIsSiteEditor } from '../companies/useIsSiteEditor';
import { invoiceTaskSubTypes } from '../models/Invoice';

export interface TaskEntryProps {
  tasks: AnyTask[];
  task: AnyTask;
  setTask?: (updater: UpdaterFunction<AnyTask>) => void;
  showAssignee?: boolean;
}

const TaskEntry: React.FC<TaskEntryProps> = ({ tasks, task, setTask, showAssignee }) => {
  const typeDefinition = useMemo(() => {
    return taskDefinitionsByType[task.task_type];
  }, [task.task_type]);

  const [hasInvoiceAccess, setHasInvoiceAccess] = useState<boolean | undefined>(undefined);
  const apiService = useApiService();
  useEffect(() => {
    if (hasInvoiceAccess === undefined) {
      void (async () => {
        const access = await apiService.getCustomerAccess(task.customer_id);
        setHasInvoiceAccess(
          access.available_features.some((feature) => AuthFeature.CC_INVOICE_MANAGER === feature),
        );
      })();
    }
  }, [apiService, hasInvoiceAccess, task.customer_id]);

  const getStatusName = (status: string): string | undefined => {
    if (typeDefinition.status === undefined) {
      return generic_status_definition.find((val) => val.status === status)?.statusName;
    }
    return typeDefinition.status.statusNames.find((st) => st.status === status)?.statusName;
  };

  const subTaskType = useMemo(() => {
    if (
      task.task_type === 'invoice' &&
      task.properties !== undefined &&
      (task.properties as InvoiceTaskProperties).parent_task_id
    ) {
      const parentTask = tasks.find(
        (t) => t.uuid === (task.properties as InvoiceTaskProperties).parent_task_id,
      );
      return parentTask?.task_type && parentTask.task_type in invoiceTaskSubTypes
        ? invoiceTaskSubTypes[parentTask.task_type]
        : undefined;
    }
    return undefined;
  }, [task, tasks]);

  const isSiteEditor = useIsSiteEditor();

  if (showAssignee && !setTask) {
    throw new Error('setTask is required when showAssignee is true');
  }

  const linkTo =
    task.task_type !== 'invoice' || hasInvoiceAccess
      ? `/customer/${task.customer_id}/tasks/${task.uuid}`
      : '#';

  const linkClass = `py-3 d-flex align-items-center ${styles.task_link}`;

  const isSite = useIsSite();

  return (
    <Row className={`p-0 m-0 bg-hover-grey-95 ${styles.task_row}`}>
      <Col md={1}>
        <NavLink to={linkTo} className={linkClass}>
          <Circled className="" size="2.5em">
            <FontAwesomeIcon icon={typeDefinition?.icon ?? faLandmark} />
          </Circled>
        </NavLink>
      </Col>
      {!isSite && (
        <Col md={4}>
          <NavLink to={linkTo} className={linkClass}>
            {task.site_name ? task.site_name : `CCP${task.customer_id}`}
          </NavLink>
        </Col>
      )}
      <Col md={isSite ? 4 : 3}>
        <NavLink to={linkTo} className={linkClass}>
          {typeDefinition?.typeName || 'Unknown'}
          {subTaskType && (
            <Badge className="ms-3 bg-birch text-dark fw-normal">{subTaskType}</Badge>
          )}
        </NavLink>
      </Col>
      <Col md={isSite ? 5 : 2}>
        <NavLink to={linkTo} className={linkClass}>
          {getStatusName(task.status) || 'Unknown'}
        </NavLink>
      </Col>
      {showAssignee && setTask && (!isSite || isSiteEditor) && (
        <Col className="py-3 d-flex align-items-center gap-2" md={2}>
          <EditAssigneeDropdown
            assignee={task.assignee}
            setAssignee={(assignee: number | undefined) => {
              setTask((task) => ({ ...task, assignee: assignee }));
            }}
            display="bubble"
          />
        </Col>
      )}
    </Row>
  );
};

export default TaskEntry;
