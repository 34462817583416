import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Dimensions, SiteSummary } from '../apis/generated';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import { FilterDropdown, FilterLabel, FilterOption } from './FilterDropdown';
import styles from './SitesDashboard.module.scss';
import { makeAssigneeList } from './TasksDashboard';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import { useCompanyUsers } from './useCompanyUsers';
import { isReportCategoryFuture } from '../customer/editor/updateReportCategory';

export type SORT_KEY =
  | 'FOREST'
  | 'SEQUESTRATION'
  | 'EDIT_TIME'
  | 'SITE_NAME'
  | 'SITE_AREA'
  | undefined;

export const searchSite = (site: SiteSummary, key: string): boolean => {
  const searchKey = key.toLowerCase().trim();
  if (site.properties.name) {
    return site.properties.name.toLowerCase().includes(searchKey);
  }
  return (
    site.customer_id.toString().includes(searchKey) || `ccp${site.customer_id}`.includes(searchKey)
  );
};

type ForestCategoryKey = 'native' | 'exotic' | 'future_native' | 'future_exotic';
export type SchemeType = 'ets' | 'ccu';

/* The SitesFilters component is used to allow the user to express a combination of filters
that should be applied to both the sites and the measures that we display in graphs.
For this purpose, the filter onChange event passes two methods:
- dimensionFilter: a function that takes a Dimensions object and returns true if it should be included
- siteFilter: a function that takes a SiteSummary object and returns true if it should be included
*/
export interface SiteFilterScheme {
  dimensionFilter: (dim: Dimensions) => boolean;
  siteFilter: (site: SiteSummary) => boolean;
}

export const SitesFilters: React.FC<{
  onChange: (filter: SiteFilterScheme) => void;
}> = ({ onChange }) => {
  const companyId = useCurrentCompanyId();

  const [searchString, setSearchString] = useState<string>('');

  const { userMe } = useUserMe();
  const { users } = useCompanyUsers(companyId);
  const [filterAssignee, setFilterAssignee] = useState<Array<number | undefined>>([]);

  const assignees: (FilterOption<number | undefined> | FilterLabel)[] = useMemo(
    () => makeAssigneeList(users, userMe),
    [users, userMe],
  );

  const isForestCategoryInDimensions = (
    forestCategory: ForestCategoryKey,
    dimensions: Dimensions,
  ): boolean => {
    const forestType = dimensions.forest_type;
    const isFuture = isReportCategoryFuture(dimensions.report_category);
    switch (forestCategory) {
      case 'exotic':
        return !isFuture && forestType === 'exotic';
      case 'native':
        return !isFuture && forestType === 'native';
      case 'future_exotic':
        return isFuture && forestType === 'exotic';
      case 'future_native':
        return isFuture && forestType === 'native';
      default:
        return false;
    }
  };

  const [filterRegistrationStatus, setFilterRegistrationStatus] = useState<
    Dimensions['registration_status'][]
  >([]);
  const [filterForestCategory, setFilterForestCategory] = useState<ForestCategoryKey[]>([]);
  const [filterPlantingStatus, setFilterPlantingStatus] = useState<Dimensions['planting_status'][]>(
    [],
  );
  const [filterScheme, setFilterScheme] = useState<SchemeType[]>([]);

  useEffect(() => {
    onChange?.({
      dimensionFilter: (dim: Dimensions) =>
        (!filterRegistrationStatus.length ||
          filterRegistrationStatus.includes(dim.registration_status)) &&
        (!filterPlantingStatus.length || filterPlantingStatus.includes(dim.planting_status)) &&
        (!filterForestCategory.length ||
          filterForestCategory.some((cat) => isForestCategoryInDimensions(cat, dim))) &&
        (!filterScheme.length ||
          filterScheme.some(
            (scheme) =>
              (scheme === 'ets' && dim.credit_scheme === 'ets') ||
              (scheme === 'ccu' && dim.credit_scheme === 'alternative_scheme'),
          )),
      siteFilter: (site: SiteSummary) =>
        searchSite(site, searchString) &&
        (!filterAssignee.length || filterAssignee.includes(site.properties.assignee)),
    });
  }, [
    filterPlantingStatus,
    filterForestCategory,
    filterRegistrationStatus,
    filterScheme,
    onChange,
    searchString,
    filterAssignee,
  ]);

  return (
    <div>
      <div className="d-flex flex-row  pt-2 pb-4 gap-2 flex-wrap">
        <Form.Control
          type="text"
          placeholder="Search"
          className={`${styles.search_input} `}
          style={{ width: '15em' }}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <FilterDropdown
          filterName="Assignee"
          filterOptions={assignees}
          filter={filterAssignee}
          setFilter={setFilterAssignee}
          searcheable
        />
        <FilterDropdown
          filterName="Forest Type"
          filterOptions={[
            { label_name: 'Existing' },
            {
              display_name: 'Existing Native',
              key: 'native',
            },
            {
              display_name: 'Existing Exotic',
              key: 'exotic',
            },

            { label_name: 'Future' },

            {
              display_name: 'Planned Native',
              key: 'future_native',
            },
            {
              display_name: 'Planned Exotic',
              key: 'future_exotic',
            },
          ]}
          filter={filterForestCategory}
          setFilter={setFilterForestCategory}
        />
        <FilterDropdown
          filterName="Scheme"
          filterOptions={[
            {
              display_name: 'ETS',
              key: 'ets',
            },
            {
              display_name: 'CCU',
              key: 'ccu',
            },
          ]}
          filter={filterScheme}
          setFilter={setFilterScheme}
        />
        <FilterDropdown
          filterName="Planting Status"
          filterOptions={[
            {
              display_name: 'Draft',
              key: 'draft',
            },
            {
              display_name: 'Confirmed',
              key: 'confirmed',
            },
            {
              display_name: 'Planted',
              key: 'planted',
            },
            {
              display_name: 'No Status',
              key: null as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            },
          ]}
          filter={filterPlantingStatus}
          setFilter={setFilterPlantingStatus}
        />
        <FilterDropdown
          filterName="Registration Status"
          filterOptions={[
            {
              display_name: 'Draft',
              key: 'draft',
            },
            {
              display_name: 'Rejected',
              key: 'rejected',
            },
            {
              display_name: 'Registered',
              key: 'registered',
            },
            {
              display_name: 'Submitted',
              key: 'submitted',
            },
          ]}
          filter={filterRegistrationStatus}
          setFilter={setFilterRegistrationStatus}
        />
      </div>
    </div>
  );
};
