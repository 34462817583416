import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { formatDate, formatNum } from '../formatters/formatters';

import { Dimensions, SiteSummary } from '../apis/generated';
import stylesTask from './TaskEntry.module.scss';
import { EditAssigneeDropdown } from './EditAssigneeDropdown';
import { measureSite } from './dimensions-and-measures/measures';
import { DimensionDict, DimensionFilter } from './dimensions-and-measures/dimensions';
import { isFutureForest } from './CarbonIntelligencePanel';

const CustomerFilteredEntry: React.FC<{
  value?: number | null;
  totalValue?: number | null;
  unit?: string | React.ReactNode;
}> = ({ value, totalValue, unit }) => {
  return (
    <div>
      {totalValue === value || totalValue === undefined || totalValue === null ? (
        <div className="fs-5">{formatNum(value)}</div>
      ) : (
        <div className="fs-5 d-flex align-items-sm-baseline gap-1">
          {`${formatNum(value, 0)}`}{' '}
          <small className="text-muted">{`of ${formatNum(totalValue, 0)}`}</small>
        </div>
      )}
      {unit && <small className="text-muted">{unit}</small>}
    </div>
  );
};

export const isCurrentForest = (d: Dimensions) =>
  d.forest_type !== 'no_forest' && !isFutureForest(d);

const CustomerEntryV2: React.FC<{
  dimensions: DimensionDict;
  customer: SiteSummary;
  filter: DimensionFilter;
  setCustomer: (site: SiteSummary) => void;
}> = ({ customer, dimensions, filter, setCustomer: updateSiteProperties }) => {
  const linkTo = `/customer/${customer.customer_id}`;
  const linkClass = `py-3 d-flex ${stylesTask.task_link}`;

  const measures = measureSite(dimensions, customer, filter);
  const measuresAll = measureSite(dimensions, customer, () => true);
  const currentForest = measureSite(dimensions, customer, (d) => isCurrentForest(d) && filter(d));
  const currentForestAll = measureSite(dimensions, customer, isCurrentForest);

  return (
    <Row className={`m-0 bg-hover-grey-95 ${stylesTask.task_row}`}>
      <Col lg={3}>
        <NavLink to={linkTo} className={linkClass}>
          <div className="align-content-center">
            {customer.properties.name ? customer.properties.name : `CCP${customer.customer_id}`}
          </div>
        </NavLink>
      </Col>
      <Col lg={2}>
        <NavLink to={linkTo} className={linkClass}>
          <CustomerFilteredEntry
            value={measures.aea}
            totalValue={measuresAll.aea}
            unit="Hectares"
          />
        </NavLink>
      </Col>
      <Col lg={2}>
        <NavLink to={linkTo} className={linkClass}>
          <CustomerFilteredEntry
            value={currentForest.aea}
            totalValue={currentForestAll.aea}
            unit="Hectares"
          />
        </NavLink>
      </Col>
      <Col lg={2}>
        <NavLink to={linkTo} className={linkClass}>
          <CustomerFilteredEntry
            value={measures.seq[0]}
            totalValue={measuresAll.seq[0]}
            unit={
              <>
                tonnes of CO<sub>2</sub>
              </>
            }
          />
        </NavLink>
      </Col>
      <Col lg={2}>
        <NavLink to={linkTo} className={linkClass}>
          <div className="align-content-center">{formatDate(customer?.edit_time)}</div>
        </NavLink>
      </Col>
      <Col className="py-3 d-flex align-items-center gap-2" lg={1}>
        <EditAssigneeDropdown
          setAssignee={async (ass) => {
            updateSiteProperties({
              ...customer,
              properties: {
                ...customer.properties,
                assignee: ass,
              },
            });
          }}
          assignee={customer.properties.assignee}
        />
      </Col>
    </Row>
  );
};

export default CustomerEntryV2;
