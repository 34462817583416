import _ from 'lodash';
import { Dimensions, Measures, SiteSummary } from '../../apis/generated';
import { DimensionFilter, getDimensionKeys } from './dimensions';

export const LUC_CLASSES = ['1', '2', '3', '4', '5', '6', '7', '8'];

export const aggregateMeasures = (measures: Measures[]): Measures => {
  const seqYears = measures.length ? _.range(measures[0].seq.length) : [];
  return {
    aea: _.sumBy(measures, (m) => m.aea),
    seq: seqYears.map((i) => _.sumBy(measures, (m) => m.seq[i])),
    luc: Object.fromEntries(
      LUC_CLASSES.map((luc) => [luc, _.sumBy(measures, (m) => m.luc?.[luc] ?? 0)]),
    ),
  };
};

export const measureSite = (
  dimensions: {
    [key: string]: Dimensions;
  },
  site: SiteSummary,
  filter: DimensionFilter,
): Measures => {
  const measures = getDimensionKeys(dimensions, filter)
    .map((key) => site.measures[key])
    .filter((m) => m !== undefined);
  return aggregateMeasures(measures);
};
