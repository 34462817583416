import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export enum TokenType {
  url_token_invalid = 'url-token-invalid',
  url_token_too_old = 'url-token-too-old',
  url_token_valid = 'url-token-valid',
  no_token = 'no-token',
}

export type AuthState =
  | {
      type: TokenType.url_token_invalid;
      token: string;
    }
  | {
      type: TokenType.url_token_too_old;
      token: string;
    }
  | {
      type: TokenType.url_token_valid;
      token: string;
    }
  | {
      type: TokenType.no_token;
      token: undefined;
    };

interface CarbonCropJWT {
  exp?: number;
}

export const useUrlTokenState = (): AuthState => {
  const loc = useLocation();

  const tokenState = useMemo<AuthState>(() => {
    const queryData = queryString.parse(loc.search);
    if (typeof queryData.token !== 'string') {
      return {
        type: TokenType.no_token,
        token: undefined,
      };
    }
    const urlToken = queryData.token;
    try {
      const token = jwtDecode(urlToken) as CarbonCropJWT;
      if (!token.exp) {
        return {
          type: TokenType.url_token_invalid,
          token: urlToken,
        };
      }
      if (token.exp < Date.now() / 1000) {
        return {
          type: TokenType.url_token_too_old,
          token: urlToken,
        };
      }
      return {
        type: TokenType.url_token_valid,
        token: urlToken,
      };
    } catch (e) {
      return {
        type: TokenType.url_token_invalid,
        token: urlToken,
      };
    }
  }, [loc]);

  return tokenState;
};
