import {
  approximatePolygonWidth,
  cropToTitleBoundaries,
  etsPolygonWidth,
  flattenFeatureCollection,
  flattenMultiFeatureCollection,
  PolygonStatusType,
  removeSmallPolygons,
} from '@nai/ets-polygons';
import {
  convertArea,
  Feature,
  featureCollection,
  FeatureCollection,
  Geometry,
  Id,
  Properties,
} from '@turf/helpers';
import { bbox, center, centroid, difference, distance } from '@turf/turf';
import _ from 'lodash';
import { isFeatureGeometryValid } from '../components/validation/geometry';
import { BoundaryFeatureCollection } from '../contexts/remote-data/useBoundary';
import {
  MULTIPLE_VALUES,
  MultiValue,
  T_MULTIPLE_VALUES,
} from '../customer/editor/merge-properties';
import { CreditSchemeType, ReportCategoryType } from './Blocks';
import { getPolygonStatus, isLocked, STATUS_MAP } from './PolygonStatus';
import { CommonGeoJsonProperties, SegmentFeature, SegmentsFeatureCollection } from './Segments';
import { isEtsCandidate, isEtsScheme } from './ets_util';

const SECURE_ETS_CATEGORIES: (ReportCategoryType | undefined)[] = [
  'existing_exotic_ets_forest',
  'existing_indigenous_ets_forest',
];

const ETS_CATEGORIES = [
  ...SECURE_ETS_CATEGORIES,
  'future_native_planting',
  'future_exotic_planting',
  'potential_ets_forest',
];

export const getCreditScheme = (properties: CommonGeoJsonProperties): CreditSchemeType => {
  if (properties.credit_scheme) {
    return properties.credit_scheme;
  }

  return ETS_CATEGORIES.includes(properties.report_category) ? 'ets' : 'alternative_scheme';
};

export const isEts = (properties: CommonGeoJsonProperties): boolean => {
  return isEtsScheme(getCreditScheme(properties));
};

export const getEtsFilingCategory = (feature: SegmentFeature) => {
  // Emissions returns in the ETS must be done separately for permanent and standard
  // forestry, this function returns that category for ETS polygons
  switch (getCreditScheme(feature.properties)) {
    case 'ets':
      return 'Permanent';
    case 'ets_stock_change':
    case 'ets_averaging':
      return 'Standard';
    default:
      throw new Error('Cannot get filing category for non-ETS polygon');
  }
};

export const getCreditSchemeMulti = (
  properties: MultiValue<CommonGeoJsonProperties>,
): CreditSchemeType | T_MULTIPLE_VALUES | undefined => {
  if (properties.credit_scheme === MULTIPLE_VALUES) {
    return MULTIPLE_VALUES;
  }
  return properties.credit_scheme;
};

export interface SegmentLabelData {
  major_text: string;
  minor_text: string;
  locked: boolean;
}

export const getLabel = (properties: CommonGeoJsonProperties): SegmentLabelData => {
  const getCreditSchemeLabel = (): string | undefined => {
    const scheme = getCreditScheme(properties);
    if (properties.ets_caa_number) {
      if (isEtsScheme(scheme)) {
        return `CAA ${properties.ets_caa_number}`;
      }
      // Show CTR number until scheme is clarified, not logically determined
      if (properties.credit_scheme === 'alternative_scheme') {
        return `CTR ${properties.ets_caa_number}`;
      }
    }

    return undefined;
  };

  const creditSchemeLabel = getCreditSchemeLabel();

  const descriptorAsMajor = creditSchemeLabel === undefined;
  const descriptor = properties.descriptor || '';

  const status = getPolygonStatus(properties.status);
  const statusData = STATUS_MAP[status];
  const statusKey = status === 'draft' ? '' : ` (${statusData.name})`;

  return {
    major_text: descriptorAsMajor ? descriptor : creditSchemeLabel,
    minor_text: `${descriptorAsMajor ? '' : descriptor}${statusKey}`,
    locked: isLocked(properties),
  };
};

export const getSegmentById = (
  segments: SegmentsFeatureCollection | undefined,
  id: Id | undefined,
): SegmentFeature | undefined => {
  if (id === undefined || segments === undefined) {
    return undefined;
  }
  return segments?.features.find((f) => f.id === id);
};

export const filterExcluded = (segment: SegmentFeature): boolean => {
  return !segment.properties.is_excluded;
};

export const filterToCategory = (
  segment: SegmentFeature,
  categories: ReportCategoryType[] | undefined,
): boolean => {
  if (categories === undefined) {
    return true;
  }
  return categories.includes(segment.properties.report_category as ReportCategoryType);
};

export const filterToPolygonStatus = (
  segment: SegmentFeature,
  polygonStatusType: PolygonStatusType[] | undefined,
): boolean => {
  if (polygonStatusType === undefined) {
    return true;
  }
  return polygonStatusType.includes(getPolygonStatus(segment.properties.status));
};

type WidthResult =
  | {
      method: 'approximate';
      width: number;
    }
  | {
      method: 'ets';
      width: number;
    }
  | {
      method: 'failed';
      width: undefined;
    }
  | {
      method: 'manual';
      width: number;
    }
  | {
      method: T_MULTIPLE_VALUES;
      width: number;
    };

/**
 * Checks if a polygon is the correct report category to be registered in the ETS
 *
 * @param feature Geojson feature to test
 * @returns True if this report category can be restistered in ETS
 */
export const isEtsRegisterableCategory = (feature: SegmentFeature): boolean => {
  const reportCategory = feature.properties?.report_category;
  return SECURE_ETS_CATEGORIES.includes(reportCategory);
};

/**
 * Calculate the width of a segment, using the ETS central area rules if it is
 * an ETS-applicable feature.
 *
 * @param feature
 * @returns
 */
export const calculateWidth = (feature: SegmentFeature): WidthResult => {
  if (feature.properties.width_override) {
    return { method: 'manual', width: feature.properties.width_override };
  }

  if (!isFeatureGeometryValid(feature)) {
    return { method: 'failed', width: undefined };
  }

  if (isEtsRegisterableCategory(feature)) {
    const etsWidth = etsPolygonWidth(feature);
    if (etsWidth !== undefined) {
      return { method: 'ets', width: etsWidth };
    }
  }

  const approxWidth = approximatePolygonWidth(feature);
  if (approxWidth !== undefined) {
    return { method: 'approximate', width: approxWidth };
  }

  return { method: 'failed', width: undefined };
};

/**
 * Removes any geojson features with invalid (eg null, empty array) geometry.
 *
 * This sort of geometry can be generated by QGIS or formed through difference operations.
 */
export const filterInvalidGeometry = <T extends Geometry, P>(
  features: FeatureCollection<T, P>,
): FeatureCollection<T, P> => {
  const validFeatures = features.features.filter((f) => {
    if (
      f.geometry === null ||
      f.geometry === undefined ||
      f.geometry.coordinates.flat().length === 0
    ) {
      return false;
    }
    return true;
  });
  return featureCollection(validFeatures);
};

export const numberByLocation = (
  segmentsToNumber: SegmentFeature[],
  offset: number,
  field: keyof CommonGeoJsonProperties,
  orderFunc: (feature: SegmentFeature) => number,
): SegmentsFeatureCollection => {
  if (segmentsToNumber === undefined) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }
  const ordered_segments = _.sortBy(segmentsToNumber, orderFunc).reverse();

  const segmentsWithNumbers = segmentsToNumber.map((f) => {
    const i = ordered_segments.indexOf(f);
    const descriptor = i === -1 ? undefined : i + 1 + offset;
    return {
      ...f,
      properties: {
        ...f?.properties,
        [field]: descriptor,
      },
    };
  });
  return {
    type: 'FeatureCollection',
    features: segmentsWithNumbers,
  };
};

export const getHighestNumberBy = <T>(segments: T[], field: (f: T) => number): number => {
  if (segments.length === 0) {
    return 0;
  }
  const numbers = segments.map(field);
  return Math.max(...numbers);
};

/**
 * Returns true if the segment needs a CAA number
 *
 * @param f SegmentFeature
 * @returns
 */
export const needsCaaNumber = (f: SegmentFeature): boolean => {
  if (!isEtsCandidate(f.properties)) {
    return false;
  }
  if (f.properties.is_excluded) {
    return false;
  }
  if (f.properties.is_ets_registered || f.properties.status === 'registered') {
    return false;
  }
  if (f.properties.ets_caa_number) {
    return false;
  }
  return true;
};

export const getHighestCaaNumber = (segments: SegmentFeature[]): number => {
  const etsPolygons = segments.filter((f) => isEtsCandidate(f.properties));
  return getHighestNumberBy(etsPolygons, (f) => f.properties.ets_caa_number ?? 0);
};

export const numberCAAs = (segments: SegmentsFeatureCollection): SegmentsFeatureCollection => {
  const needsNumbers = segments.features.filter(needsCaaNumber);
  const nonEtsCandidates = segments.features.filter((f) => !needsCaaNumber(f));

  const offset = getHighestCaaNumber(segments.features);

  const nowNumbered = numberByLocation(
    needsNumbers,
    offset,
    'ets_caa_number',
    (f) => centroid(f).geometry.coordinates[1],
  );

  return featureCollection([...nowNumbered.features, ...nonEtsCandidates]);
};

/**
 * Returns true if the segment needs a CTR number
 *
 * @param f SegmentFeature
 * @returns
 */
export const needsCtrNumber = (f: SegmentFeature): boolean => {
  if (f.properties.report_category !== 'native_carboncrop') {
    return false;
  }
  if (f.properties.is_excluded) {
    return false;
  }
  if (f.properties.ets_caa_number) {
    return false;
  }
  return true;
};

export const getHighestCtrNumber = (segments: SegmentFeature[]): number => {
  const ctrPolygons = segments.filter((f) => f.properties.report_category === 'native_carboncrop');
  return getHighestNumberBy(ctrPolygons, (f) => f.properties.ets_caa_number ?? 0);
};

export const numberCTRs = (segments: SegmentsFeatureCollection): SegmentsFeatureCollection => {
  const ctrCandidates = segments.features.filter(needsCtrNumber);
  const nonCtrCandidates = segments.features.filter((f) => !needsCtrNumber(f));

  const offset = getHighestCtrNumber(segments.features);
  const numberedCtrs = numberByLocation(
    ctrCandidates,
    offset,
    'ets_caa_number',
    (f) => centroid(f).geometry.coordinates[1],
  );

  return featureCollection([...numberedCtrs.features, ...nonCtrCandidates]);
};

export const cutOverlaps = (
  segments: SegmentsFeatureCollection,
  selectedFeature: SegmentFeature,
): SegmentFeature | undefined => {
  const segmentsWithoutSelected = flattenFeatureCollection(
    featureCollection(segments.features.filter((f) => f !== selectedFeature)),
  );
  const newGeometry = difference(selectedFeature, segmentsWithoutSelected)?.geometry;
  if (newGeometry !== undefined) {
    const newFeature = {
      ...selectedFeature,
      geometry: newGeometry,
    };
    return newFeature;
  }
  return undefined;
};

const separateByLockedStatus = (segments: SegmentsFeatureCollection) => {
  const lockedSegments = flattenMultiFeatureCollection({
    ...segments,
    features: segments.features.filter((f) => f.properties.locked),
  });
  const unlockedSegments = flattenMultiFeatureCollection({
    ...segments,
    features: segments.features.filter((f) => !f.properties.locked),
  });
  return { lockedSegments: lockedSegments, unlockedSegments: unlockedSegments };
};

export const cropToBoundary = (
  segments: SegmentsFeatureCollection,
  boundary: BoundaryFeatureCollection,
): SegmentsFeatureCollection => {
  const validSegments: SegmentsFeatureCollection = {
    ...segments,
    features: segments.features.filter((seg) => isFeatureGeometryValid(seg)),
  };
  const { lockedSegments, unlockedSegments } = separateByLockedStatus(validSegments);

  const flattened = flattenMultiFeatureCollection(unlockedSegments);
  const trimmedPolygons = cropToTitleBoundaries(
    flattened,
    boundary,
    0.1, // Distance from border in meters
  );
  const newPolygons = removeSmallPolygons(
    flattenMultiFeatureCollection(trimmedPolygons),
    convertArea(0.001, 'hectares', 'meters'),
  );
  return { ...newPolygons, features: newPolygons.features.concat(lockedSegments.features) };
};

export const filterSmallPolygons = (
  segments: SegmentsFeatureCollection,
  removePolygonSizeHectares: number,
): SegmentsFeatureCollection => {
  const validSegments: SegmentsFeatureCollection = {
    ...segments,
    features: segments.features.filter((seg) => isFeatureGeometryValid(seg)),
  };
  const { lockedSegments, unlockedSegments } = separateByLockedStatus(validSegments);
  const newPolygons = removeSmallPolygons(
    unlockedSegments,
    convertArea(removePolygonSizeHectares, 'hectares', 'meters'),
  );
  return { ...newPolygons, features: newPolygons.features.concat(lockedSegments.features) };
};

/**
 * Converts a polygon feature collection to center and zoom coordinates.
 * This makes it easy to jump to set the map to view this polygon
 *
 * @param polygon The polygon feature collection.
 * @returns An object with the center coordinates and zoom level.
 */
export const polygonToCenterAndZoom = (
  polygon: FeatureCollection | Feature,
): { center: [number, number]; zoom: number } => {
  const [minX, minY, maxX, maxY] = bbox(polygon);
  const degreesSpan = distance([minX, minY], [maxX, maxY], { units: 'degrees' });
  const zoom = Math.log2(360 / degreesSpan);

  // If you are planning to turn this into averaging minX and maxX
  // think about the dateline
  const ctrCenter = center(polygon).geometry.coordinates as [number, number];
  return { center: ctrCenter, zoom: zoom };
};

/**
 * Parses nested properties in a JSON object.
 *
 * @param json JSON string to parse
 * @returns Parsed object
 */
export function parseSafe<T>(value: string | T): T {
  if (typeof value !== 'string') {
    return value;
  }
  return JSON.parse(value);
}

/**
 * JSON.parse can not work with nested objects, so we need to parse them manually.
 *
 * @param properties @turf/helpers.Properties
 * @returns CommonGeoJsonProperties
 */
export const parseSegmentsProperties = (properties: Properties): CommonGeoJsonProperties => {
  if (!properties) {
    return {};
  }

  const keys = [
    'comments',
    'class_labels',
    'alternative_certification',
    'mpi_report',
    'ets_registration',
    'ineligible_reasons',
  ];
  return Object.keys(properties)
    .filter((key) => keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: parseSafe(properties[key]) }), {
      ...properties,
    }) as CommonGeoJsonProperties;
};
