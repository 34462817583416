import { InvoiceStatus, PaymentStatus } from '../apis/generated';

export const invoiceTaskSubTypes: { [key: string]: string } = {
  'per.2024': 'PER 2024',
  per: 'PER 2023',
};

export interface Invoice {
  invoice_id: number;
  invoice_number: string;
  invoice_date: string;
  total_ex_tax: string;
  total_inc_tax: string;
  tax: string;
  status: InvoiceStatus;
  properties: InvoiceProperties;
  local_id?: string; // ephemeral ID used to the client to match between server and client versions of an invoice
}

export interface InvoiceProperties {
  sequestration_period: InvoiceSequestrationPeriod;
  service_fee_proportion: string;
  nzus_issued: InvoiceNZUsIssued;
  costs_and_disbursements?: InvoiceCostAndDisbursement[];
  already_invoiced_costs_and_disbursements?: InvoiceCostAndDisbursement[];
  cc_service_fee: InvoiceCostAndDisbursement;
  service_fee_calculation: CalculatedServiceFee;
  payment: CalculatedPayment;
}

export interface InvoiceCostAndDisbursementWithId extends InvoiceCostAndDisbursement {
  id: number;
}

export interface InvoiceSequestrationPeriod {
  // Period of sequestration for invoice. //

  start?: string;
  end: string;
}

export interface InvoiceNZUsIssued {
  // NZUs issues and NZU price data for invoice. //

  nzu_price: string;
  amount: string;
}

export interface InvoiceCostAndDisbursement {
  // Cost and disbursement for invoice. //

  description: string;
  quantity: string;
  total_ex_tax: string;
}

export interface CalculatedServiceFee {
  // Internal data during service fee calculation. //

  gross_yield: string;
  costs_ex_tax: string;
  gross_yield_net_costs: string;
  service_fee_ex_tax: string;
}

export interface CalculatedPayment {
  // Calculation result for payment. //

  balance_brought_forward: string;
  total_payable: string;
  total_paid_now_in_nzu: string;
  total_paid_now_in_nzd: string;
  balance_carried_forward: string;
}

export interface Payment {
  payment_id: number;
  invoice_id: number;
  amount: string;
  currency: string;
  currency_rate: string;
  status: PaymentStatus;
}
