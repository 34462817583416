import { faAngleDown, faAngleUp, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import tinycolor from 'tinycolor2';
import { Dimensions, Measures, SiteSummary } from '../apis/generated';
import { useCompaniesCustomersV2 } from '../contexts/remote-data/useCompaniesCustomersV2';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import { formatNum } from '../formatters/formatters';
import { cc_charcoal, cc_lime, nzu_blue, nzu_orange } from '../theme/colors';
import BagelChart from './BagelChart';
import styles from './SitesDashboard.module.scss';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import ChocolateBar from '../customer/financials/ChocolateBar';
import { NZUPriceData, useCCUPrice, useNZUPrice } from './NZUCustomPrice';
import { useIsCarbonCropEmployee } from '../companies/useIsCarbonCropEmployee';
import { DimensionFilter, getDimensionKeys } from './dimensions-and-measures/dimensions';
import { aggregateMeasures, LUC_CLASSES } from './dimensions-and-measures/measures';

/* A slice is a category of data that we want to display in the chart */
interface Slice {
  label: string;
  filter: DimensionFilter;
  color: string;
}
export const isFutureForest = (d: Dimensions) =>
  d.report_category === 'future_native_planting' || d.report_category === 'future_exotic_planting';

const INELIGIBLE: Slice = {
  label: 'Ineligible',
  filter: (d: Dimensions) => d.report_category === 'pre_1990_forest',
  color: cc_charcoal,
};

const UNFORESTED_OTHER: Slice = {
  label: 'Unforested/Other',
  filter: (d: Dimensions) => d.forest_type === 'no_forest' || d.forest_type === 'unknown',
  color: cc_charcoal,
};
const NATIVE: Slice = {
  label: 'Native',
  filter: (d: Dimensions) => d.forest_type === 'native' && !isFutureForest(d),
  color: nzu_blue,
};

const EXOTIC: Slice = {
  label: 'Exotic',
  filter: (d: Dimensions) => d.forest_type === 'exotic' && !isFutureForest(d),
  color: nzu_orange,
};
const FUTURE_NATIVE: Slice = {
  label: 'Planned Native',
  filter: (d: Dimensions) => d.forest_type === 'native' && isFutureForest(d),
  color: tinycolor(nzu_blue).lighten(30).toString(),
};

const FUTURE_EXOTIC: Slice = {
  label: 'Planned Exotic',
  filter: (d: Dimensions) => d.forest_type === 'exotic' && isFutureForest(d),
  color: tinycolor(nzu_orange).lighten(30).toString(),
};

function getToppings<T>(
  slices: Slice[],
  makeData: (filter: DimensionFilter) => T,
): { color: string; label: string; data: T }[] {
  return slices.map((slice) => ({
    color: slice.color,
    label: slice.label,
    data: makeData(slice.filter),
  }));
}

const CarbonIntelligencePanel: React.FC<{
  sites: SiteSummary[];
  showtCO2: boolean;
  dimensions?: Record<string, Dimensions>;
}> = ({ sites, showtCO2, dimensions = {} }) => {
  const companyId = useCurrentCompanyId();
  const { customers } = useCompaniesCustomersV2(companyId);

  const { userMe } = useUserMe();

  const { nzuPrice } = useNZUPrice();
  const { ccuPrice } = useCCUPrice();

  /** This is the magic sauce.
   *
   * This function takes a filter which determines which elements to include, and then
   * measures all sites according to that filter, and returns the aggregated measures.
   *
   * It caches the results of the measurements so that if the same filter is used again,
   * it can return the cached result.
   */
  const measureBy = useMemo(() => {
    const cache = new Map<DimensionFilter, Measures>();
    return (filter: DimensionFilter) => {
      const existing = cache.get(filter);
      if (existing) return existing;
      const dimensionKeys = getDimensionKeys(dimensions, filter);
      const allMeasures = sites.flatMap((site) =>
        dimensionKeys.map((key) => site.measures[key]).filter((m) => m !== undefined),
      );
      const aggregatedMeasure = aggregateMeasures(allMeasures);
      cache.set(filter, aggregatedMeasure);
      return aggregatedMeasure;
    };
  }, [dimensions, sites]);

  const totalSitesArea = measureBy(() => true).aea;

  const [showCharts, setShowCharts] = useState(false);

  const getSequestration = (fn: DimensionFilter) => measureBy(fn).seq;

  const getSequestrationInDollars = useCallback(
    (filter: DimensionFilter) => {
      const add = (a: number[], b: number[]) => _.zipWith(a, b, (a, b) => (a ?? 0) + (b ?? 0));
      const getDollars = (measures: Measures, carbonPrice: NZUPriceData | undefined) =>
        measures.seq.map(
          (seq, i) =>
            seq *
            (carbonPrice && customers
              ? carbonPrice.values[customers.start_year + 1 + i - carbonPrice.start_year]
              : 0),
        );
      const isCcuSequestration = (d: Dimensions) => d.credit_scheme === 'alternative_scheme';
      return add(
        getDollars(
          measureBy((d) => !isCcuSequestration(d) && filter(d)),
          nzuPrice,
        ),
        getDollars(
          measureBy((d) => isCcuSequestration(d) && filter(d)),
          ccuPrice,
        ),
      );
    },
    [measureBy, customers, nzuPrice, ccuPrice],
  );

  const isCarboncropEmployee = useIsCarbonCropEmployee();

  const totalSequestration = measureBy(() => true).seq[0];
  const totalSequestrationInDollars = getSequestrationInDollars(() => true)[0];

  const currentForestArea = measureBy(
    (d) => (d.forest_type === 'native' || d.forest_type === 'exotic') && !isFutureForest(d),
  ).aea;

  const barChartRange = _.range(0, 10).map((i) => ((customers?.start_year ?? 0) + i).toString());
  return (
    <div className="pb-3">
      <div className={`p-2 ${styles.carbon_intelligence_pannel} text-light`}>
        <Row className="m-0 p-3">
          <Col lg={4} className="p-0 gap-2 d-flex align-items-center">
            <div className="d-flex gap-2">
              <div className="fs-3">Carbon Intelligence</div>
              <div className="fs-3 fw-lighter fst-italic">BETA</div>
            </div>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="fs-4">{formatNum(sites.length)}</div>
            <small className="fw-lighter fst-italic">total sites</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              <div className="fs-4">{formatNum(totalSitesArea)}</div>
              <div className="fs-6">ha</div>
            </div>

            <small className="fw-lighter fst-italic">total area</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              <div className="fs-4">{formatNum(currentForestArea)}</div>
              <div className="fs-6">ha</div>
            </div>

            <small className="fw-lighter fst-italic">current forest</small>
          </Col>
          <Col className="p-0 d-flex flex-column align-items-center" lg={2}>
            <div className="d-flex gap-2 align-items-sm-baseline">
              {showtCO2 ? (
                <>
                  <div className="fs-4">{formatNum(totalSequestration)}</div>
                  <div className="fs-6">tCO₂</div>
                </>
              ) : (
                <>
                  <div className="fs-4">${formatNum(totalSequestrationInDollars)}</div>
                </>
              )}
            </div>
            <small className="fw-lighter fst-italic">
              {showtCO2 ? (
                <> total sequestration {customers?.start_year}</>
              ) : (
                <>total revenue {customers?.start_year}</>
              )}
            </small>
          </Col>
        </Row>
        {showCharts && (
          <>
            <Row className="d-flex flex-column align-items-center">
              <div style={{ minHeight: '0.1em', width: '90%' }} className="bg-white" />
            </Row>
            <Row style={{}} className="py-3 ">
              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>Forest area</p>
                  <small className="fw-lighter fst-italic">(ha)</small>
                </div>
                <BagelChart
                  toppings={getToppings(
                    [NATIVE, EXOTIC, FUTURE_NATIVE, FUTURE_EXOTIC, INELIGIBLE],
                    (fn) => measureBy(fn).aea,
                  )}
                  hoverLabel="ha"
                />
              </Col>

              <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                <div className="d-flex gap-2 align-items-sm-baseline">
                  <p>{`${showtCO2 ? 'Sequestration' : 'Revenue'}`}</p>
                  <small className="fw-lighter fst-italic">
                    {showtCO2 ? '(tCO₂/year)' : `($NZ/year)`}
                  </small>
                </div>
                <ChocolateBar
                  toppings={getToppings(
                    [NATIVE, EXOTIC, FUTURE_NATIVE, FUTURE_EXOTIC],
                    showtCO2 ? getSequestration : getSequestrationInDollars,
                  )}
                  emptyLabel="No data"
                  xLabels={barChartRange}
                />
              </Col>
              {isCarboncropEmployee && (
                <Col xs={4} className="d-flex flex-column align-items-center col-xl-4 col-12">
                  <div className="d-flex gap-2 align-items-sm-baseline">
                    <p>Land use capability</p>
                    <small className="fw-lighter fst-italic">(ha)</small>
                  </div>
                  <ChocolateBar
                    toppings={getToppings(
                      [NATIVE, EXOTIC, FUTURE_NATIVE, FUTURE_EXOTIC, UNFORESTED_OTHER],
                      (fn: DimensionFilter) => {
                        const measure = measureBy(fn);
                        return LUC_CLASSES.map((luc) => measure.luc?.[luc] ?? 0);
                      },
                    )}
                    emptyLabel="No data"
                    xLabels={LUC_CLASSES}
                    horizontal
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </div>
      <Row>
        <Col xs={4} />
        <Col xs={4} className="d-flex flex-column align-items-center">
          <FontAwesomeIcon
            icon={showCharts ? faAngleUp : faAngleDown}
            size="lg"
            className="rounded py-0 cursor-pointer"
            style={{
              transform: 'translate(0px, -50%)',
              backgroundColor: cc_charcoal,
              width: '2em',
            }}
            color="white"
            onClick={() => {
              setShowCharts(!showCharts);
            }}
          />
        </Col>
        <Col xs={4} className="d-flex flex-column align-items-end">
          {showCharts && (
            <div
              style={{
                transform: 'translate(0px, -60%)',
              }}
              className="pe-4"
            >
              <a
                href={`https://docs.google.com/forms/d/e/1FAIpQLScmdm9-CxMSz-11Y2FYrKZl0H_LStGOf4tFFc8Tyyw9ACdV-A/viewform?usp=pp_url&entry.524841042=${userMe?.email}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: cc_lime,
                  fontSize: '0.85em',
                  backgroundColor: cc_charcoal,
                }}
                className="px-3 py-1 rounded d-flex gap-2 text-decoration-none"
              >
                <div>Give feedback</div>
                <FontAwesomeIcon
                  icon={faCommentDots}
                  size="lg"
                  color={cc_lime}
                  onClick={() => {
                    setShowCharts(!showCharts);
                  }}
                />
              </a>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CarbonIntelligencePanel;
